@font-face {
  font-family: "c4th_culture_fontregular";
  src: url("../assets/fonts/primary_font-webfont.woff2") format("woff2"),
    url("../assets/fonts/primary_font-webfont.woff") format("woff");
  font-weight: bolder;
  font-style: normal;
}

@font-face {
  font-family: "roboto";
  src: url("../assets/fonts/roboto-bold-webfont.woff2") format("woff2"),
    url("../assets/fonts/roboto-bold-webfont.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "roboto";
  src: url("../assets/fonts/roboto-bold-webfont.woff2") format("woff2"),
    url("../assets/fonts/roboto-bold-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
