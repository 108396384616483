.image {
  -webkit-animation: spin 10s linear infinite;
  -moz-animation: spin 10s linear infinite;
  animation: spin 10s linear infinite;
}
@-moz-keyframes spin {
  50% {
    -moz-transform: rotate(10deg);
  }
}
@-webkit-keyframes spin {
  50% {
    -webkit-transform: rotate(-10deg);
  }
}
@keyframes spin {
  50% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(10deg);
  }
}

.fullscreen {
  height: 100%;
  width: 100vw;
}

.forth_font_primary {
  font-family: "c4th_culture_fontregular";
  font-size: 55px;
}
.forth_font_card_primary {
  font-family: "c4th_culture_fontregular";
  font-size: 25px;
}
.forth_font_card_secondary {
  font-family: "c4th_culture_fontregular";
  font-size: 18px;
}

.forth_font_card_header {
  font-family: "c4th_culture_fontregular";
}

.text-green {
  color: rgb(65, 65, 207);
}

.comic-sans {
  font-family: "Comic Sans MS", cursive, sans-serif;
}

.Overlay2 {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rebeccapurple;
}

.success_background {
  background-image: url("../assets/images/1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.moon_nav_logo {
  background-image: url("../assets/images/1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.moon_brand {
  background-image: url("../assets/images/moon_brand.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 150%;
}

.forth_font_secondary {
  font-family: "c4th_culture_fontregular";
  font-size: 25px;
}
.forth_font_navbar {
  font-family: "c4th_culture_fontregular";
  font-size: 20px;
}

.section_height {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  height: fit-content;
  padding: 1em;
}

.section_height_team {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  height: fit-content;
}

@media (min-width: 320px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .left_text_container {
    height: 250px;
    width: auto;
    align-content: space-around;
  }
  .top_left_section {
    width: 350px;
    height: auto;
    order: 1;
    justify-content: center;
    align-items: center;
  }
  .top_right_section {
    order: 2;
    align-items: center;
  }

  .top_right_section_title {
    width: 400px;
  }

  .top_right_section_body {
    width: 250px;
    display: flex;
    align-content: center;
    justify-content: space-around;
    margin: auto;
    position: absolute;
  }
  .about_right_section {
    align-items: center;
    display: flex;
    justify-content: center;
    height: auto;
  }

  .about_right_section_title {
    width: 400px;
  }

  .about_right_section_body {
    width: 250px;
    display: flex;
    align-content: center;
    justify-content: space-around;
    margin: auto;
  }
}

.img_size_main {
  height: 450px;
  width: auto;
  transform: rotate(-10deg);
}

.margin_top_small {
  margin-top: none;
}
@media (max-width: 481px) {
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
  .margin_top_small {
    margin-top: 10em;
  }

  .img_size_main {
    height: 375px;
    width: 375px;
  }

  .left_text_container {
    height: 250px;
    width: auto;
    align-content: space-around;
  }
  .top_left_section {
    width: 400px;
    height: auto;
    order: 1;
    justify-content: center;
    align-items: center;
  }
  .top_right_section {
    order: 2;
    align-items: center;
  }

  .top_right_section_title {
    width: 400px;
  }

  .top_right_section_body {
    width: 250px;
    display: flex;
    align-content: center;
    justify-content: space-around;
    margin: auto;
  }
  .about_right_section {
    align-items: center;
    display: flex;
    justify-content: center;
    height: auto;
  }

  .about_right_section_title {
    width: 400px;
  }

  .about_right_section_body {
    width: 250px;
    display: flex;
    align-content: center;
    justify-content: space-around;
    margin: auto;
  }
}
@media (min-width: 641px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  .left_text_container {
    height: 400px;
    width: auto;
    align-content: space-around;
  }
  .top_left_section {
    width: 400px;
    height: auto;
    order: 1;
    justify-content: center;
    align-items: center;
  }
  .top_right_section {
    order: 2;
    align-items: center;
  }

  .top_right_section_title {
    width: 400px;
  }

  .top_right_section_body {
    width: 250px;
    display: flex;
    align-content: center;
    justify-content: space-around;
    margin: auto;
  }
  .about_right_section {
    align-items: center;
    display: flex;
    justify-content: center;
    height: auto;
  }

  .about_right_section_title {
    width: 400px;
  }

  .about_right_section_body {
    width: 250px;
    display: flex;
    align-content: center;
    justify-content: space-around;
    margin: auto;
  }
}
@media (min-width: 961px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .left_text_container {
    height: 400px;
    width: auto;
    align-content: space-around;
  }
  .top_left_section {
    width: 400px;
    height: auto;
    order: 1;
    justify-content: center;
    align-items: center;
  }
  .top_right_section {
    order: 2;
    align-items: center;
  }

  .top_right_section_title {
    width: 400px;
  }

  .top_right_section_body {
    width: 250px;
    display: flex;
    align-content: center;
    justify-content: space-around;
    margin: auto;
  }
  .about_right_section {
    align-items: center;
    display: flex;
    justify-content: center;
    height: auto;
  }

  .about_right_section_title {
    width: 400px;
  }

  .about_right_section_body {
    width: 250px;
    display: flex;
    align-content: center;
    justify-content: space-around;
    margin: auto;
  }
}
@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
  .left_text_container {
    height: 400px;
    width: auto;
    align-content: space-around;
  }
  .top_left_section {
    width: 400px;
    height: auto;
    order: 1;
    justify-content: center;
    align-items: center;
  }
  .top_right_section {
    order: 2;
    align-items: center;
  }

  .top_right_section_title {
    width: 400px;
  }

  .top_right_section_body {
    width: 250px;
    display: flex;
    align-content: center;
    justify-content: space-around;
    margin: auto;
  }
  .about_right_section {
    align-items: center;
    display: flex;
    justify-content: center;
    height: auto;
  }

  .about_right_section_title {
    width: 400px;
  }

  .about_right_section_body {
    width: 250px;
    display: flex;
    align-content: center;
    justify-content: space-around;
    margin: auto;
  }
}
@media (min-width: 1281px) {
  /* hi-res laptops and desktops */
  .left_text_container {
    height: 400px;
    width: auto;
    align-content: space-around;
  }
  .top_left_section {
    width: 400px;
    height: auto;
    order: 1;
    justify-content: center;
    align-items: center;
  }
  .top_right_section {
    order: 2;
    align-items: center;
  }

  .top_right_section_title {
    width: 400px;
  }

  .top_right_section_body {
    width: 250px;
    display: flex;
    align-content: center;
    justify-content: space-around;
    margin: auto;
  }
  .about_right_section {
    align-items: center;
    display: flex;
    justify-content: center;
    height: auto;
  }

  .about_right_section_title {
    width: 400px;
  }

  .about_right_section_body {
    width: 250px;
    display: flex;
    align-content: center;
    justify-content: space-around;
    margin: auto;
  }
}

.bg-purple {
  background-color: #4a2b7a;
}

.footer {
  width: 100vw;
}

.bg_who_are_degens {
  background: url("../assets/images/mobile_who_are_degens.png");
  background-repeat: no-repeat;
  background-size: auto;
  height: auto;
  width: auto;
}

/* CSS */
.top_right_section_buttons {
  appearance: button;
  background-color: #4d4ae8;
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
  border: 1px solid #4d4ae8;
  border-radius: 1rem;
  box-shadow: rgba(255, 255, 255, 0.15) 0 1px 0 inset,
    rgba(46, 54, 80, 0.075) 0 1px 1px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: Inter, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  margin: 0;
  padding: 0.5rem 1rem;
  text-align: center;
  text-transform: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  margin: 5px;
}

.top_right_section_buttons:focus:not(:focus-visible),
.top_right_section_buttons:focus {
  outline: 0;
}

.top_right_section_buttons:hover {
  background-color: #3733e5;
  border-color: #3733e5;
}

.top_right_section_buttons:focus {
  background-color: #413fc5;
  border-color: #3e3bba;
  box-shadow: rgba(255, 255, 255, 0.15) 0 1px 0 inset,
    rgba(46, 54, 80, 0.075) 0 1px 1px, rgba(104, 101, 235, 0.5) 0 0 0 0.2rem;
}

.top_right_section_buttons:active {
  background-color: #3e3bba;
  background-image: none;
  border-color: #3a38ae;
  box-shadow: rgba(46, 54, 80, 0.125) 0 3px 5px inset;
}

.top_right_section_buttons:active:focus {
  box-shadow: rgba(46, 54, 80, 0.125) 0 3px 5px inset,
    rgba(104, 101, 235, 0.5) 0 0 0 0.2rem;
}

.top_right_section_buttons:disabled {
  background-image: none;
  box-shadow: none;
  opacity: 0.65;
  pointer-events: none;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
